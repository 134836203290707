// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Required for views
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// Enable dropdowns and modals via the data API
import 'popper.js';
import 'bootstrap';

// Enable rails UJS
import railsUJS from '@rails/ujs';
railsUJS.start();

import '@miriamtech/if2';
import '@miriamtech/if2-processors';
import '@miriamtech/if2-work_orders';
import '@miriamtech/if2-integrations';

require('@rails/activestorage').start();
require('channels');

require("trix")
require("@rails/actiontext")

// Support component names relative to this directory:
var componentRequireContext = require.context('components', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);
